import React from "react";
import "./Login.scss";
import logo from "../images/logo.svg";
import { TextField } from "formik-material-ui";
import { Formik, Form, Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/auth";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    background: "white",
    color: "black",
    width: "100%",
    marginBottom: "1.25em",
    "& .MuiInputBase-input": {
      color: "black",
    },
  },
});

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);

  // TODO implement this method
  function isAuthenticated() {
    return false;
  }
  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {isAuthenticated() && (
        <div>
          <p>La autenticación ha sido exitosa</p>
        </div>
      )}

      {!isAuthenticated() && (
        <>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Requerido";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = "Email invalido";
              }
              if (!values.password) {
                errors.password = "Requerido";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(login(values.email, values.password))
                .then(() => {
                  setSubmitting(false);
                })
                .catch(() => {
                  // if( error.response.status==401 ){
                  //     alert("usuario o contraseña invalida")
                  // }else{
                  //     alert("Ha ocurrido un error " + error.message);
                  // }
                  setSubmitting(false);
                });
            }}
          >
            {({ submitForm, isSubmitting, errors }) => (
              <Form>
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div className="logo">
                        <img
                          src={logo}
                          alt="Logo - JPD- Judo Piramidal Dinámico"
                        />
                      </div>
                      <h1 className="text-center">Autenticación</h1>
                      <p>Por favor digite sus datos de acceso</p>
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="email">Email</label>
                        </div>
                        <div className="col-12">
                          <Field
                            component={TextField}
                            name="email"
                            type="email"
                            id="email"
                            className={classes.root}
                            variant="outlined"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="password">Clave</label>
                        </div>
                        <div className="col-12">
                          <Field
                            component={TextField}
                            id="password"
                            name="password"
                            type="password"
                            className={classes.root}
                            variant="outlined"
                            color="primary"
                          ></Field>
                        </div>
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={submitForm}
                        disabled={isSubmitting}
                      >
                        Ingresar
                      </Button>
                    </div>
                    <div className="col introduccion">
                      <h2>¿Qué es JPD - Judo Piramidal Dinámico</h2>
                      <p>
                        Es una plataforma para participar en torneos deportivos
                        de JUDO
                      </p>
                      <p>
                        Si usted tiene problemas para acceder en la plataforma
                        móvil, puede contartarnos en
                        <a href="mailto:soporte@judopd.com">
                          soporte@judopd.com
                        </a>
                      </p>
                      {/*
                            <h2>¿Aún no tiene clave?</h2>
                            <ul>
                                <li><a [routerLink]="['/usuario/registro']">Registrese como persona natural</a></li>
                            <li><a [routerLink]="['/club-deportivo/registro']">Registrese como club deportivo</a></li>
                            </ul>
                            --> */}
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default Login;
