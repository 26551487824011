import axios from "axios";
import authHeader from "./auth-header";
import { API_URL, API } from "../AppConstants";

const getSportClubs = () => {
  let url = `${API_URL}${API.club.getAllSportsClubs}`;
  url = url.replace("{page}", 1).replace("{limit}", 20);

  return axios.get(url, { headers: authHeader() }).then((response) => {
    return response.data;
  });
};

const getSportsClub = (id) => {
  let url = `${API_URL}${API.club.getSportClub}`;
  url = url.replace("{id}", id);

  return axios.get(url, { headers: authHeader() }).then((response) => {
    return response.data;
  });
};

const exported = {
  getSportClubs,
  getSportsClub,
};

export default exported;
