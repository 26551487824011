import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./UserProfile.scss";
import UserService from "../services/user.service";
import {
  Breadcrumbs,
  CircularProgress,
  makeStyles,
  TableContainer,
  Typography,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { API, API_URL } from "../AppConstants";
import { AccountBox } from "@material-ui/icons";
import {
  QueryClient,
  useQueryClient,
  QueryClientProvider,
  useQuery,
} from "react-query";

const UserProfile = () => {
  const [loading, setLoading] = useState(false);
  let { id } = useParams();
  const {
    isLoading,
    isError,
    data: user,
  } = useQuery(["userProfile", id], UserService.getUser);

  const useStyles = makeStyles({
    table: {
      minWidth: "100%",
    },
  });
  const classes = useStyles();

  return (
    <div className="container profile-user-page">
      <div className="row justify-content-center">
        <div className="col-12">
          <h1>Perfil de usuario</h1>
          {!isLoading && !isError && (
            <>
              <Breadcrumbs>
                <Link color="inherit" to="/">
                  Inicio
                </Link>
                <Link color="inherit" to="/user">
                  Usuarios
                </Link>
                {
                  <Typography color="textPrimary">
                    {user.nombre} {user.apellidos}
                  </Typography>
                }
              </Breadcrumbs>
              <div className="row">
                <TableContainer className="col-8">
                  {
                    <Table className={classes.table} aria-label="Usuario">
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Usuario ID
                          </TableCell>
                          <TableCell align="left">{user.usuarioId}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Nombre
                          </TableCell>
                          <TableCell align="left">{user.nombre}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Apellidos
                          </TableCell>
                          <TableCell align="left">{user.apellidos}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Tipo de documento de identidad
                          </TableCell>
                          <TableCell align="left">
                            {user.documentoDeIdentidadTipo}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Documento de identidad
                          </TableCell>
                          <TableCell align="left">
                            {user.documentoDeIdentidad}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Peso
                          </TableCell>
                          <TableCell align="left">{user.peso}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Fecha de nacimiento
                          </TableCell>
                          <TableCell align="left">
                            {user.fechaDeNacimiento.date}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Email
                          </TableCell>
                          <TableCell align="left">{user.email}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Teléfono 1
                          </TableCell>
                          <TableCell align="left">{user.telefono1}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Teléfono 2
                          </TableCell>
                          <TableCell align="left">{user.telefono2}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            EPS
                          </TableCell>
                          <TableCell align="left">{user.eps}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Prepagada
                          </TableCell>
                          <TableCell align="left">{user.prepagada}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Estado
                          </TableCell>
                          <TableCell align="left">{user.estadoId}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Club
                          </TableCell>
                          <TableCell align="left">{user.clubId}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Sexo
                          </TableCell>
                          <TableCell align="left">{user.sexoId}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Participacion en torneos
                          </TableCell>
                          <TableCell align="left">
                            {user.participacionEnTorneos}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Grado de cinturon
                          </TableCell>
                          <TableCell align="left">
                            {user.gradoCinturon.nombre}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Ciudad
                          </TableCell>
                          <TableCell align="left">
                            {user.ciudad.nombre}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Departamento
                          </TableCell>
                          <TableCell align="left">
                            {user.departamento.nombre}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Pais
                          </TableCell>
                          <TableCell align="left">{user.pais.nombre}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Club
                          </TableCell>
                          <TableCell align="left">{user.club.nombre}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Roles
                          </TableCell>
                          <TableCell align="left">{user.roles}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  }
                </TableContainer>
                {
                  <div className="col-4 profile-picture">
                    {user.profileImageMd !== "" ? (
                      <img src={user.profileImageMd} alt="Profile avatar" />
                    ) : (
                      <AccountBox className="profile-icon" />
                    )}
                  </div>
                }
              </div>
            </>
          )}
          {isLoading && <CircularProgress />}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
