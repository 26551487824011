import { Breadcrumbs, Button, Typography } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import "./SportsClubUsers.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import UserService from "../services/user.service";
import { makeStyles } from "@material-ui/core/styles";
import ClubService from "../services/sport-clubs.service";
import DeleteIcon from "@material-ui/icons/Delete";
import { User } from "../Entities/User";
import { SportClub } from "../Entities/SportClub";
import { UserTypes } from "../Entities/UserType";
import AddUserToClubModal from "../Modals/AddUserToClub/AddUserToClubModal";

export const SportsClubUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [sportsClub, setSportClub] = useState<SportClub>();
  const [reload, setReload] = useState(false);

  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    setLoading(true);

    ClubService.getSportsClub(id).then((response) => {
      setSportClub(response);
      UserService.getSportsClubUsers(id, UserTypes.ADMIN).then((response) => {
        setUsers(response.usuarios);
        setLoading(false);
      });
    });
  }, [id]);

  const removeAdminFromClub = (userId: number, clubId: number) => {
    setLoading(true);
    UserService.removeAdminFromClub(userId, clubId).then((response) => {
      UserService.getSportsClubUsers(id, UserTypes.ADMIN).then(
        (response: { usuarios: User[] }) => {
          setUsers(response.usuarios);
          setLoading(false);
        },
      );
    });
  };

  const useStyles = makeStyles({
    table: {},
    tableCell: {},
  });
  const classes = useStyles();

  useEffect(() => {
    if (reload) {
      setLoading(true);
      UserService.getSportsClubUsers(id, UserTypes.ADMIN).then((response) => {
        setUsers(response.usuarios);
        setLoading(false);
        setReload(false);
      });
    }
  }, [id, reload]);
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          {<h1>{sportsClub?.nombre}</h1>}
          <Breadcrumbs aria-label="breadcrumbs">
            <Link color="inherit" to="/">
              Inicio
            </Link>
            <Link color="inherit" to="/sports-club">
              Clubes deportivos
            </Link>
            <Typography color="textPrimary">
              Usuarios {sportsClub?.nombre}
            </Typography>
          </Breadcrumbs>
          {!loading && (
            <>
              <div className="float-right">
                {sportsClub && (
                  <AddUserToClubModal
                    clubId={sportsClub.clubId}
                    setReload={setReload}
                  />
                )}
              </div>

              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>Id</TableCell>
                      <TableCell className={classes.tableCell}>
                        Nombre
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        Apellidos
                      </TableCell>
                      <TableCell className={classes.tableCell}>Email</TableCell>
                      <TableCell className={classes.tableCell}>
                        Documento de identidad
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        Acciones
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((row: User, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.tableCell}>
                          {row.usuarioId}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.nombre}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.apellidos}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.email}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.documentoDeIdentidad}
                        </TableCell>
                        <TableCell>
                          <Button
                            color="secondary"
                            variant="outlined"
                            onClick={(e) => {
                              if (sportsClub) {
                                removeAdminFromClub(
                                  row.usuarioId,
                                  sportsClub.clubId,
                                );
                              }
                            }}
                            startIcon={<DeleteIcon />}
                          >
                            Quitar del club
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {loading && <CircularProgress />}
        </div>
      </div>
    </div>
  );
};

export default SportsClubUsers;
