import "./Tournaments.scss";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UserService from "../services/user.service";
import Block from "@material-ui/icons/Block";
import { useHistory } from "react-router-dom";
import {
  Person,
  SettingsInputAntennaTwoTone,
  VerifiedUser,
} from "@material-ui/icons";
import TournamentService from "../services/tournament.service";
import moment from "moment";

const useStyles = makeStyles({
  table: {},
  tableCell: {},
});
const Tournaments = () => {
  const [tournaments, setTournaments] = new useState([]);
  const [loading, setLoading] = new useState(false);

  useEffect(() => {
    setLoading(true);
    TournamentService.getTournaments().then((response) => {
      let tournaments = response.torneos.map((torneo) => {
        torneo.valorInscripcion = currencyToPesos(torneo.valorInscripcion);
        torneo.valorPremio = currencyToPesos(torneo.valorPremio);
        torneo.costoRecompraXCombate = currencyToPesos(
          torneo.costoRecompraXCombate,
        );

        return torneo;
      });
      setTournaments(tournaments);
      setLoading(false);
    });
  }, []);

  const currencyToPesos = (value) => {
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
    }).format(value);
  };

  const timestampToDate = (timestamp) => {
    const date = moment.unix(timestamp).zone("-0500").format("DD/MM/YYYY");
    return date;
  };
  const classes = useStyles();
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <h1>Torneos</h1>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Id</TableCell>
                  <TableCell className={classes.tableCell}>Categoria</TableCell>
                  <TableCell className={classes.tableCell}>Sexo</TableCell>
                  <TableCell className={classes.tableCell}>Ciudad</TableCell>
                  <TableCell className={classes.tableCell}>
                    Inscripción
                  </TableCell>
                  <TableCell className={classes.tableCell}>Premio</TableCell>
                  <TableCell className={classes.tableCell}>
                    Recompra por combate
                  </TableCell>
                  <TableCell className={classes.tableCell}>Inicio</TableCell>
                  <TableCell className={classes.tableCell}>Fin</TableCell>
                  <TableCell className={classes.tableCell}>
                    Máximo de solicitud de combates
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Publicación de resultados por los clubes
                  </TableCell>
                </TableRow>
              </TableHead>
              {!loading && (
                <TableBody>
                  {tournaments.map((row) => (
                    <TableRow key={row.torneoId}>
                      <TableCell>{row.torneoId}</TableCell>
                      <TableCell>{row.categoria.nombre}</TableCell>
                      <TableCell>{row.categoria.sexo.nombre}</TableCell>
                      <TableCell>{row.ciudad.nombre}</TableCell>
                      <TableCell>{row.valorInscripcion}</TableCell>
                      <TableCell>{row.valorPremio}</TableCell>
                      <TableCell>{row.costoRecompraXCombate}</TableCell>
                      <TableCell>
                        {row.fechaInicio
                          ? timestampToDate(row.fechaInicio.timestamp)
                          : ""}
                      </TableCell>
                      <TableCell>
                        {row.fechaFin
                          ? timestampToDate(row.fechaFin.timestamp)
                          : ""}
                      </TableCell>
                      <TableCell>
                        {row.fechaFinRealizacionSolicitudesCombate
                          ? timestampToDate(
                              row.fechaFinRealizacionSolicitudesCombate
                                .timestamp,
                            )
                          : ""}
                      </TableCell>
                      <TableCell>
                        {row.fechaFinPublicacionResultadosCombate
                          ? timestampToDate(
                              row.fechaFinPublicacionResultadosCombate
                                .timestamp,
                            )
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {loading && (
            <div className="d-flex mt-2 justify-content-center">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tournaments;
