import React from "react";
import "./PrivacyPolicy.scss";
const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 ">
          <h1>Términos y Condiciones de la Aplicación Móvil</h1>
          <div class="agreement-container">
            <p>
              <span>
                Este documento establece las condiciones mediante las cuales se
                regir&aacute; el uso de la aplicaci&oacute;n de&nbsp;
              </span>
              <strong>
                <span>&ldquo;Judopd&rdquo;</span>
              </strong>
              <span>
                , aplicaci&oacute;n operada por Pyramidal Dynamics Sports
                constituida los Estados Unidos y domiciliada en la ciudad de Los
                &Aacute;ngeles California, creadora del producto: Torneos&nbsp;
              </span>
              <strong>
                <span>JUDO PIRAMIDAL DIN&Aacute;MICO JPD.</span>
              </strong>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Pyramidal Dyn&aacute;mics Sports L.L.C. se dedica a la
                programaci&oacute;n y realizaci&oacute;n de torneos
                presenciales, inicialmente de Judo, en los cuales el usuario
                podr&aacute; inscribirse para participar en los torneos&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>
                &nbsp;y competir y recibir premios econ&oacute;micos.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Los T&eacute;rminos y Condiciones pueden ser modificados en
                cualquier momento, y cada una de las modificaciones, ser&aacute;
                efectiva despu&eacute;s de su publicaci&oacute;n en nuestra
                P&aacute;gina Web xxxxx, por tanto, invitamos a que revise y
                entienda en su totalidad los T&eacute;rminos y Condiciones. Para
                el caso de no estar de acuerdo, le sugerimos abstenerse de
                acceder a nuestro contenido y de navegar en la xxxxxx
                (p&aacute;gina Web).
              </span>
            </p>
            <p>
              <span>
                El lector se compromete a leer los T&eacute;rminos y Condiciones
                aqu&iacute; establecidos, antes de efectuar su
                inscripci&oacute;n, en caso de realizar la inscripci&oacute;n,
                se entiende que cuenta con el conocimiento integral de este
                documento y la consecuente aceptaci&oacute;n de la totalidad de
                sus condiciones.
              </span>
            </p>
            <p>
              <span>
                El lector reconoce que el ingreso de su informaci&oacute;n
                personal y la de los datos que contiene la aplicaci&oacute;n a
                su disposici&oacute;n, los realiza de manera voluntaria y que
                cumple con las leyes del pa&iacute;s en donde se realice el
                torneo. En caso de que se acceda por parte de menores de edad,
                &eacute;stos podr&aacute;n conocer los contenidos de la
                aplicaci&oacute;n, pero no podr&aacute;n inscribirse por no ser
                mayores de 18 a&ntilde;os, que para el caso colombiano procede
                la mayor&iacute;a de edad.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span>DEFINICIONES</span>
              </strong>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span>Categor&iacute;a o divisi&oacute;n de peso</span>
              </strong>
              <span>:</span>
              <strong>
                <span>&nbsp;</span>
              </strong>
              <span>
                rango definido por un peso m&aacute;ximo y otro m&iacute;nimo.
              </span>
            </p>
            <p>
              <strong>
                <span>Combate de Judo</span>
              </strong>
              <span>
                : confrontaci&oacute;n entre dos practicantes de Judo, el cual
                est&aacute; compuesto de tres sets con descanso de 30&rdquo;
                entre set y set.&nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span>Competidor</span>
              </strong>
              <span>
                : que compite con otros que aspiran a un mismo objetivo, para el
                caso en los torneos&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>.</span>
            </p>
            <p>
              <strong>
                <span>Contenido</span>
              </strong>
              <span>
                : son todos los textos, im&aacute;genes, videos y productos
                que&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>&nbsp;publica en la Judopp.&nbsp;</span>
            </p>
            <p>
              <strong>
                <span>Correo electr&oacute;nico</span>
              </strong>
              <span>:</span>
              <strong>
                <span>&nbsp;</span>
              </strong>
              <span>medio por el cual&nbsp;</span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>
                &nbsp;y el Competidor tendr&aacute;n comunicaci&oacute;n directa
                referente a temas concernientes a los torneos que&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>&nbsp;programe y realice. &nbsp;</span>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>Club deportivo de Judo</span>
              </strong>
              <span>
                : asociaci&oacute;n con su propia personalidad jur&iacute;dica,
                conformada por personas jur&iacute;dicas o f&iacute;sicas. Este
                tiene como objetivo la promoci&oacute;n y la pr&aacute;ctica del
                Judo y al cual debe pertenecer cualquier Competidor en JPD.
              </span>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>Derechos Intelectuales</span>
              </strong>
              <span>
                : son aquellos que se confieren a las personas sobre las
                creaciones de su mente.
              </span>
            </p>
            <p>
              <strong>
                <span>Descanso</span>
              </strong>
              <span>
                : tiempo de receso del combate de 30&rdquo; y va entre set y
                set.
              </span>
            </p>
            <p>
              <strong>
                <span>Fecha de nacimiento</span>
              </strong>
              <span>:</span>
              <strong>
                <span>&nbsp;</span>
              </strong>
              <span>
                esta nos garantiza saber de la mayor&iacute;a de edad del
                Competidor.
              </span>
            </p>
            <p>
              <strong>
                <span>Franja</span>
              </strong>
              <span>
                : es el nombre dado a los 6 niveles de crecimiento que tiene el
                sistema llamado Judo Piramidal Din&aacute;mico. Inicia con la
                primera franja: amarillo, la segunda franja: naranja, la tercera
                franja: verde, la cuarta franja: azul, la quinta franja:
                marr&oacute;n y la sexta franja: negra, de esta &uacute;ltima
                sale el ganador del torneo. &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span>Grado de cintur&oacute;n</span>
              </strong>
              <span>
                : el Judo tiene 6 colores: amarillo, naranja, verde, azul,
                marr&oacute;n y negro, este &uacute;ltimo tiene niveles de
                1&deg; Dan, 2&deg; Dan, hasta el 10&deg; Dan.
              </span>
              <strong>
                <span>&nbsp;</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>IP</span>
              </strong>
              <span>
                : es una representaci&oacute;n num&eacute;rica del punto de
                internet donde est&aacute; conectado un dispositivo.
              </span>
            </p>
            <p>
              <strong>
                <span>JPD</span>
              </strong>
              <span>: Judo Piramidal Din&aacute;mico.</span>
            </p>
            <p>
              <strong>
                <span>Judo</span>
              </strong>
              <span>
                : arte marcial japon&eacute;s. Deporte del ciclo
                ol&iacute;mpico. &nbsp; &nbsp; &nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span>Judopp</span>
              </strong>
              <span>
                : aplicaci&oacute;n m&oacute;vil App para inscribirse a los
                torneos que&nbsp;
              </span>
              <strong>
                <span>JPD&nbsp;</span>
              </strong>
              <span>programe y realice.</span>
            </p>
            <p>
              <strong>
                <span>Modalidad</span>
              </strong>
              <span>
                : definici&oacute;n seg&uacute;n el sexo, para el caso modalidad
                masculina y femenina.&nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span>Nombre y apellidos</span>
              </strong>
              <span>
                : permiten identificar al Competidor que se inscribe para
                participar en los torneos&nbsp;
              </span>
              <strong>
                <span>JPD.</span>
              </strong>
              <span>&nbsp;</span>
            </p>
            <p>
              <strong>
                <span>Peso</span>
              </strong>
              <span>
                : permite conocer, en qu&eacute; peso se inscribe el competidor,
                para definir en cual divisi&oacute;n de peso podr&aacute;
                participar.&nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span>Seguro de salud (EPS)</span>
              </strong>
              <span>:</span>
              <strong>
                <span>&nbsp;</span>
              </strong>
              <span>
                documento indispensable para poder compartir, garantizando la
                atenci&oacute;n en caso de lesiones u otras situaciones que
                correspondan a la salud f&iacute;sica y mental del Competidor.
              </span>
            </p>
            <p>
              <strong>
                <span>Set</span>
              </strong>
              <span>
                : espacio de tiempo de combate continuo entre dos Competidores.
                &nbsp;&nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span>Sexo</span>
              </strong>
              <span>:</span>
              <strong>
                <span>&nbsp;</span>
              </strong>
              <span>
                necesario para definir la categor&iacute;a en que podr&aacute;
                participar, sea de hombres o mujeres.&nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span>Tel&eacute;fono</span>
              </strong>
              <span>:</span>
              <strong>
                <span>&nbsp;</span>
              </strong>
              <span>
                medio de comunicaci&oacute;n inmediata que permitir&aacute;
                informar y solicitar informaci&oacute;n el Competidor.&nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span>Temporada</span>
              </strong>
              <span>
                : lapso en el que se realizar&aacute; la convocatoria.&nbsp;
              </span>
            </p>
            <p>
              <strong>
                <span>Tipo de documento</span>
              </strong>
              <span>:</span>
              <strong>
                <span>&nbsp;</span>
              </strong>
              <span>
                indispensable para constatar varios datos del Competidor que
                desea participar en los torneos&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>&nbsp;y poderle dar la transparencia</span>
              <strong>
                <span>&nbsp;</span>
              </strong>
              <span>a los torneos&nbsp;</span>
              <strong>
                <span>JPD.</span>
              </strong>
            </p>
            <p>
              <strong>
                <span>Torneos JPD</span>
              </strong>
              <span>
                : son competencias de Judo en las categor&iacute;as
                cl&aacute;sicas, tanto en masculino como en femenino.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span>ALCANCE Y USO</span>
              </strong>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                En la Judopd, se pondr&aacute; a disposici&oacute;n del
                Competidor, la informaci&oacute;n necesaria que debe diligenciar
                para inscribirse y poder participar en los torneos que
                realiza&nbsp;
              </span>
              <strong>
                <span>JPD,&nbsp;</span>
              </strong>
              <span>
                su completa informaci&oacute;n es indispensable para poder
                participar en los torneos&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>
                , por lo tanto, el Competidor, se hace responsable de la
                veracidad, autenticidad y vigencia de la informaci&oacute;n
                suministrada, sin embargo,&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>
                , exigir&aacute; en los torneos, los documentos f&iacute;sicos
                que acrediten la veracidad de la informaci&oacute;n, para
                garantizar la transparencia en los torneos&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>.&nbsp;</span>
            </p>
            <p>
              <strong>
                <span>JPD&nbsp;</span>
              </strong>
              <span>
                podr&aacute; adicionar, modificar o eliminar las funcionalidades
                en cualquier momento, lo cual acepta el Competidor mediante la
                instalaci&oacute;n de la aplicaci&oacute;n.&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>
                &nbsp;podr&aacute; realizar modificaciones, las cuales
                ser&aacute;n notificadas al Competidor a trav&eacute;s de la
                Judopd.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span>REQUISITOS PARA USO</span>
              </strong>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                El Competidor deber&aacute; contar con un dispositivo
                m&oacute;vil inteligente (Smartphone) o Tableta con sistema
                operativo Android o IOS y con acceso a internet.&nbsp;
              </span>
            </p>
            <p>
              <span>
                Para acceder al portal, el Competidor solicitar&aacute; a&nbsp;
              </span>
              <strong>
                <span>JPD&nbsp;</span>
              </strong>
              <span>
                un link que conduce a la Judopp, all&iacute; se le
                otorgar&aacute; un Usuario y una Clave, Clave que deber&aacute;
                personalizar al terminar de hacer la debida inscripci&oacute;n.
                Adicional a lo anterior, se requerir&aacute; que el Competidor
                registre unas preguntas de seguridad, las cuales ser&aacute;n
                solicitadas al momento de querer ingresar al portal a
                trav&eacute;s de un equipo no registrado.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span>OBLIGACIONES DE LOS USUARIOS</span>
              </strong>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                El Competidor se obliga a usar la Judopd y los contenidos
                encontrados en ella de una manera diligente, correcta y
                l&iacute;cita y a abstenerse de las conductas siguientes:
              </span>
            </p>
            <p>
              <span>
                -Utilizar los contenidos de forma, con fines o efectos
                contrarios a la ley, a la moral, a las buenas costumbres y/o al
                reglamento.
              </span>
            </p>
            <p>
              <span>
                -Reproducir, copiar, representar, utilizar, distribuir,
                transformar o modificar los contenidos de la aplicaci&oacute;n o
                permitir el acceso del p&uacute;blico a trav&eacute;s de
                cualquier modalidad de comunicaci&oacute;n p&uacute;blica.
              </span>
            </p>
            <p>
              <span>
                -Utilizar los contenidos de cualquier manera que entra&ntilde;en
                un riesgo de da&ntilde;o o inutilizaci&oacute;n de la
                aplicaci&oacute;n o de los contenidos o de terceros.
              </span>
            </p>
            <p>
              <span>
                -Suprimir, eludir o manipular el derecho de autor y dem&aacute;s
                datos identificativos de los derechos de autor incorporados a
                los contenidos y/o a los dispositivos t&eacute;cnicos de
                protecci&oacute;n.
              </span>
            </p>
            <p>
              <span>
                -Emplear los contenidos de la informaci&oacute;n obtenida a
                trav&eacute;s de la Judopp para distribuir, transmitir, remitir,
                modificar, rehusar o reportar la publicidad o los contenidos con
                fines comerciales.
              </span>
            </p>
            <p>
              <span>
                -No permitir que terceros usen la aplicaci&oacute;n m&oacute;vil
                con su clave.
              </span>
            </p>
            <p>
              <span>
                -Utilizar la Judopd y los contenidos con fines l&iacute;citos
                y/o il&iacute;citos, contrarios a lo establecido en estos
                T&eacute;rminos y Condiciones, o lesivos de los derechos e
                intereses de terceros, o que puedan da&ntilde;ar, inutilizar,
                sobrecargar o deteriorar la aplicaci&oacute;n y los contenidos o
                que impidan la normal utilizaci&oacute;n y/o disfrute de esta y
                de sus contenidos.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span>PROPIEDAD INTELECTUAL</span>
              </strong>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Todo el material inform&aacute;tico, gr&aacute;fico,
                publicitario, fotogr&aacute;fico, de multimedia, audiovisual y
                de dise&ntilde;o, as&iacute; como todos los contenidos, textos y
                bases de datos puestos a su disposici&oacute;n en esta
                aplicaci&oacute;n, est&aacute;n protegidos por derechos de autor
                y/o propiedad industrial cuyo titular es&nbsp;
              </span>
              <strong>
                <span>PJD</span>
              </strong>
              <span>
                , y en algunos casos de terceros que han autorizado su uso o
                explotaci&oacute;n. Todos los contenidos en la aplicaci&oacute;n
                est&aacute;n protegidos por las normas sobre derecho de autor y
                por todas las normas nacionales e internacionales que le sean
                aplicables.
              </span>
            </p>
            <p>
              <span>
                Queda prohibido todo acto de copia, reproducci&oacute;n,
                modificaci&oacute;n, creaci&oacute;n de trabajos derivados,
                venta o distribuci&oacute;n, exhibici&oacute;n de los contenidos
                de esta aplicaci&oacute;n, de manera o por medio alguno.
              </span>
            </p>
            <p>
              <span>
                Cualquier uso no autorizado de los contenidos constituir&aacute;
                una violaci&oacute;n del presente documento y a las normas
                vigentes sobre derechos de autor, a las normas vigentes
                nacionales e internacionales sobre Propiedad Industrial, y a
                cualquier otra que sea aplicable.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span>USO DE INFORMACI&Oacute;N Y PRIVACIDAD</span>
              </strong>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span>
                  Consentimiento de Autorizaci&oacute;n de Tratamiento de Datos
                  Personales y Autorizaci&oacute;n de Im&aacute;genes y Videos
                  que da el Competidor a JPD
                </span>
              </strong>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Como este es un modelo que se implementar&aacute; inicialmente
                en Colombia, se citan para el caso las leyes colombianas.&nbsp;
              </span>
            </p>
            <p>
              <span>
                De conformidad con la Ley 1581 de 2012 (Ley de Protecci&oacute;n
                de Datos Personales) y el Decreto 1377 de 2013 (Decreto que
                reglamenta la Ley 1581 de 2012), los Competidores, autorizan de
                manera previa, expresa e informada a&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>
                , para que realice cualquier operaci&oacute;n sobre sus datos
                personales, que incluye: recolecci&oacute;n, acceso,
                almacenamiento, utilizaci&oacute;n, entrega, transmisi&oacute;n,
                transferencia y/o supresi&oacute;n de sus datos personales
                conforme a la Pol&iacute;tica de Tratamiento de la
                Informaci&oacute;n de&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>.</span>
            </p>
            <p>
              <span>
                La aceptaci&oacute;n de estos T&eacute;rminos y Condiciones,
                implica tambi&eacute;n, la aprobaci&oacute;n del Consentimiento
                de Autorizaci&oacute;n de Tratamiento de Datos Personales y
                Autorizaci&oacute;n de Im&aacute;genes y Videos que da el
                Competidor a&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>:</span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <em>
                <span>
                  Dando cumplimiento a lo dispuesto en la Ley 1581 de 2012
                  &ldquo;por el cual se dictan disposiciones generales para la
                  protecci&oacute;n de datos personales&rdquo; y de conformidad
                  al Decreto 1377 de 2013, con la firma de este documento
                  manifiesto que he sido informado por&nbsp;
                </span>
              </em>
              <strong>
                <em>
                  <span>JPD&nbsp;</span>
                </em>
              </strong>
              <em>
                <span>de lo siguiente:</span>
              </em>
            </p>
            <p>
              <em>
                <span>-</span>
              </em>
              <strong>
                <em>
                  <span>JPD</span>
                </em>
              </strong>
              <em>
                <span>
                  &nbsp;actuar&aacute; como responsable del Tratamiento mis
                  Datos Personales de los cuales soy titular y que, conjunta o
                  separadamente podr&aacute; recolectar, usar y tratar mis datos
                  personales conforme la pol&iacute;tica de Tratamiento de Datos
                  que aparece en la&nbsp;
                </span>
              </em>
              <strong>
                <em>
                  <span>Judopp</span>
                </em>
              </strong>
              <em>
                <span>.</span>
              </em>
            </p>
            <p>
              <em>
                <span>
                  -Que me ha sido informada que la finalidad de la
                  recolecci&oacute;n de mis datos personales ser&aacute; para la
                  promoci&oacute;n de productos y servicios de&nbsp;
                </span>
              </em>
              <strong>
                <em>
                  <span>JPD</span>
                </em>
              </strong>
              <em>
                <span>.</span>
              </em>
            </p>
            <p>
              <em>
                <span>
                  -Mis derechos como titular de los datos son los previstos en
                  la Constituci&oacute;n Colombiana y en la Ley, especialmente
                  el derecho a conocer, actualizar, rectificar y suprimir
                  informaci&oacute;n personal, as&iacute; como el derecho a
                  revocar el consentimiento acordado, otorgado para el
                  tratamiento de datos personales.
                </span>
              </em>
            </p>
            <p>
              <em>
                <span>
                  -Los derechos pueden ser ejercidos a trav&eacute;s de los
                  canales dispuestos por&nbsp;
                </span>
              </em>
              <strong>
                <em>
                  <span>JPD&nbsp;</span>
                </em>
              </strong>
              <em>
                <span>
                  y observando la pol&iacute;tica de Tratamiento de Datos
                  Personales de&nbsp;
                </span>
              </em>
              <strong>
                <em>
                  <span>JPD</span>
                </em>
              </strong>
              <em>
                <span>.</span>
              </em>
            </p>
            <p>
              <em>
                <span>-</span>
              </em>
              <strong>
                <em>
                  <span>&nbsp;JPD,</span>
                </em>
              </strong>
              <em>
                <span>
                  &nbsp;garantizar&aacute; la confidencialidad, libertad
                  seguridad, veracidad, transparencia, acceso y
                  circulaci&oacute;n restringida de mis datos y se
                  reservar&aacute; el derecho a modificar su pol&iacute;tica de
                  Tratamiento de Datos Personales en cualquier momento.
                  Cualquier cambio ser&aacute; informado y publicado
                  oportunamente en la P&aacute;gina Web.&nbsp;
                </span>
              </em>
            </p>
            <p>
              <em>
                <span>-Mediante el presente formato autorizo a&nbsp;</span>
              </em>
              <strong>
                <em>
                  <span>JPD</span>
                </em>
              </strong>
              <em>
                <span>
                  &nbsp;para que haga el uso y tratamiento de mis derechos de
                  imagen para incluirlos sobre fotograf&iacute;as y producciones
                  audiovisuales (videos); as&iacute; como de los Derechos de
                  Autor; los Derechos Conexos y en general todos aquellos
                  derechos de propiedad intelectual que tengan que ver con el
                  derecho de imagen.
                </span>
              </em>
            </p>
            <p>
              <em>
                <span>
                  -Esta autorizaci&oacute;n se regir&aacute; por las normas
                  legales aplicables y en particular por las siguientes:
                </span>
              </em>
            </p>
            <ol>
              <li>
                <em>
                  <ul>
                    <li>
                      <p>
                        <span>
                          Este video/foto podr&aacute; ser utilizado con fines
                          deportivos, comerciales, educativos e informativos en
                          diferentes escenarios y plataformas de&nbsp;
                        </span>
                        <strong>
                          <span>JPD</span>
                        </strong>
                        <span>.</span>
                      </p>
                    </li>
                  </ul>
                </em>
                <em>
                  <ul>
                    <li>
                      <p>
                        <span>
                          Este video/foto ser&aacute; utilizado para promover
                          los torneos&nbsp;
                        </span>
                        <strong>
                          <span>JPD</span>
                        </strong>
                        <span>
                          &nbsp;y para la venta de productos derivados de la
                          actividad de&nbsp;
                        </span>
                        <strong>
                          <span>JPD</span>
                        </strong>
                        <span>.&nbsp;</span>
                      </p>
                    </li>
                  </ul>
                </em>
                <em>
                  <ul>
                    <li>
                      <p>
                        <span>
                          La presente autorizaci&oacute;n no tiene &aacute;mbito
                          geogr&aacute;fico determinado, por lo que las
                          im&aacute;genes en las que aparezca podr&aacute;n ser
                          utilizadas en el territorio del mundo, as&iacute;
                          mismo, tampoco tiene ning&uacute;n l&iacute;mite de
                          tiempo para su concesi&oacute;n, ni para
                          explotaci&oacute;n de las im&aacute;genes, o parte de
                          estas, por lo que mi autorizaci&oacute;n se considera
                          concedida por un plazo de tiempo ilimitado.
                        </span>
                      </p>
                    </li>
                  </ul>
                </em>
              </li>
            </ol>
            <p>
              <em>
                <span>
                  La informaci&oacute;n obtenida para el Tratamiento de mis
                  Datos Personales y la Autorizaci&oacute;n de Uso de
                  Im&aacute;genes y Videos la he suministrado en forma
                  voluntaria y es ver&iacute;dica. &nbsp;
                </span>
              </em>
            </p>
            <p>
              <span>Para este fin, los Competidores en los torneos&nbsp;</span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>, autorizan a&nbsp;</span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>
                &nbsp;para publicar, en los medios utilizados por&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>
                , como redes sociales, sus registros audiovisuales, con el fin
                de informar al p&uacute;blico de la realizaci&oacute;n y
                resultado de los torneos&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>.</span>
            </p>
            <p>
              <strong>
                <span>JPD</span>
              </strong>
              <em>
                <span>,</span>
              </em>
              <span>
                &nbsp;no se hace responsable por el buen comportamiento de los
                espectadores, ni por cualquier tipo de da&ntilde;o moral,
                f&iacute;sico, material, ni cualquier otra &iacute;ndole que
                pudiera involucrarse como relacionado con los torneos
                programados por&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>.</span>
            </p>
            <p>
              <strong>
                <span>JPD</span>
              </strong>
              <span>
                &nbsp;podr&aacute; en cualquier momento antes de la entrega de
                los premios, verificar el cumplimiento de los t&eacute;rminos y
                condiciones de los Competidores y excluir a quienes no las
                cumplan dejando constancia de ello y se reserva la facultad de
                disponer del dinero del Competidor de la categor&iacute;a que se
                declara desierta.&nbsp;
              </span>
            </p>
            <p>
              <span>Los Competidores, ceden o transfieren a&nbsp;</span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>
                &nbsp;de manera irrevocable, a perpetuidad y para el territorio
                del mundo, todo y cualquier derecho que recaiga o pueda recaer
                sobre su imagen y voz, fijadas o grabadas con ocasi&oacute;n de
                la participaci&oacute;n en cualquiera de las redes
                sociales.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span>JURISDICCI&Oacute;N</span>
              </strong>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Estos t&eacute;rminos y condiciones se rigen por las leyes del
                pa&iacute;s en donde se pueda desarrollar la aplicaci&oacute;n.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span>USO DE DIRECCIONES IP</span>
              </strong>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Una direcci&oacute;n de Protocolo de Internet (IP) es un
                conjunto de n&uacute;meros que se asigna autom&aacute;ticamente
                a su o dispositivo m&oacute;vil cuando usted accede a su
                proveedor de servicios de internet, o a trav&eacute;s de la red
                de &aacute;rea local (LAN) de su organizaci&oacute;n o la red de
                &aacute;rea amplia (WAN). Los servidores web
                autom&aacute;ticamente identifican su dispositivo m&oacute;vil
                por la direcci&oacute;n IP asignada a &eacute;l durante su
                sesi&oacute;n en l&iacute;nea.
              </span>
            </p>
            <p>
              <strong>
                <span>JPD</span>
              </strong>
              <span>
                &nbsp;podr&aacute; recolectar direcciones IP para
                prop&oacute;sitos de administraci&oacute;n de sistemas y para
                auditar el uso de nuestro sitio, todo lo anterior de acuerdo con
                la autorizaci&oacute;n de protecci&oacute;n de datos que se
                suscribe para tal efecto. Normalmente no vinculamos la
                direcci&oacute;n IP de un usuario con la informaci&oacute;n
                personal de ese usuario, lo que significa que cada sesi&oacute;n
                de usuario se registra, pero el usuario sigue siendo
                an&oacute;nimo para nosotros. Sin embargo, podemos usar las
                direcciones IP para identificar a los usuarios de nuestro sitio
                cuando sea necesario con el objeto de para exigir el
                cumplimiento de los t&eacute;rminos de uso del sitio, o para
                proteger nuestro servicio, sitio u otros usuarios.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span>SEGURIDAD</span>
              </strong>
            </p>
            <p>
              <br />
            </p>
            <p>
              <strong>
                <span>JPD&nbsp;</span>
              </strong>
              <span>
                est&aacute; comprometido en la protecci&oacute;n de la seguridad
                de su informaci&oacute;n personal.&nbsp;
              </span>
              <strong>
                <span>JPD</span>
              </strong>
              <span>
                &nbsp;tiene implementados mecanismos de seguridad que aseguran
                la protecci&oacute;n de la informaci&oacute;n personal,
                as&iacute; como los accesos &uacute;nicamente al personal y
                sistemas autorizados, tambi&eacute;n contra la p&eacute;rdida,
                uso indebido y alteraci&oacute;n de sus datos de usuario bajo
                nuestro control.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span>
                Declaro haber le&iacute;do y aceptado en su totalidad, todos los
                T&eacute;rminos y Condiciones establecidos por la&nbsp;
              </span>
              <strong>
                <span>Judopd</span>
              </strong>
              <span>.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
