export const API_URL = process.env.REACT_APP_API_URL;

export const API = {
  usuario: {
    create: "usuarios",
    getAll: "v1/usuarios?page={page}&limit={limit}",
    get: "v1/usuarios/{id}",
    removeSportsClub: "v1/usuarios/{userId}/remove-sports-club",
    removeAdminFromClub: "v1/usuarios/{userId}/remove-admin-from-club/{clubId}",
    addAdminToClub: "v1/usuarios/{userId}/add-admin-to-club/{clubId}",
    enableDisableUser: "v1/usuarios/{userId}/enable-disable/{newStatus}",
    resetPassword: "usuarios/reset-password",
  },
  autenticacion: {
    getToken: "login_check",
  },
  matches: {
    getAll: "v1/combates",
    get: "v1/combates/{id}",
    schedule: "v1/combates/{id}/programar",
  },
  club: {
    create: "clubes",
    getAllSportsClubs: "v1/clubes?page={page}&limit={limit}",
    getSportClub: "v1/clubes/{id}",
  },
  ciudad: {
    getAll: "v1/ciudades",
  },
  categoria: {
    getAll: "v1/categorias",
  },
  torneos: {
    getAll: "v1/torneos",
  },
};

export const ERROR_MESSAGES = {
  expiredToken: "Expired JWT Token",
};

export const USER_ROLES = {
  user: "ROLE_USER",
  admin: "ROLE_ADMIN",
};
