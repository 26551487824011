import {
  Button,
  ButtonGroup,
  Modal,
  TextField,
  makeStyles,
} from "@material-ui/core";
import PersonAdd from "@material-ui/icons/PersonAdd";
import React, { Dispatch, SetStateAction, useState } from "react";
import "./AddUserToClubModal.scss";
import UserService from "../../services/user.service";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "rgba(0, 0, 0, 1)",
    border: "2px solid gray",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: theme.zIndex.modal + 1,
  },
}));
type AddUserToClubModalProps = {
  clubId: number;
  setReload: Dispatch<SetStateAction<boolean>>;
};
const AddUserToClubModal = ({ clubId, setReload }: AddUserToClubModalProps) => {
  const classes = useStyles();
  const [userId, setUserId] = useState("");
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const save = () => {
    setLoading(true);
    UserService.addAdminToClub(userId, clubId).then((response) => {
      setReload(true);
      setLoading(false);
      handleClose();
    });
  };

  return (
    <div>
      <ButtonGroup variant="contained" color="primary" aria-label="Acciones">
        <Button onClick={handleOpen} startIcon={<PersonAdd />}>
          Agregar administrador de club
        </Button>
      </ButtonGroup>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2>Agregar administrador de club</h2>
          <p>
            El ID puedes consultarlo en la primera columna del listado de todos
            los usuarios
          </p>
          <div>
            <TextField
              onChange={(event) => {
                setUserId(event.target.value);
              }}
              id="outlined-basic"
              label="Usuario"
              variant="outlined"
              placeholder="ID Usuario"
            />
          </div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              save();
            }}
            style={{ marginTop: 16 }}
            disabled={loading}
            startIcon={<PersonAdd />}
          >
            Guardar
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AddUserToClubModal;
