import "./Users.scss";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UserService from "../services/user.service";
import Block from "@material-ui/icons/Block";
import { useHistory } from "react-router-dom";
import { Person, VerifiedUser } from "@material-ui/icons";

const useStyles = makeStyles({
  table: {},
  tableCell: {},
});

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [savingNewStatus, setSavingNewStatus] = useState(false);
  const [reloadUsers, setReloadUsers] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    UserService.getUsers().then((response) => {
      setUsers(response.usuarios);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (reloadUsers) {
      setLoading(true);
      UserService.getUsers().then((response) => {
        setUsers(response.usuarios);
        setLoading(false);
        setReloadUsers(false);
      });
    }
  }, [reloadUsers]);

  const enableDisableUser = (userId, newStatus) => {
    setSavingNewStatus(true);
    UserService.enableDisableUser(userId, newStatus).then((response) => {
      setSavingNewStatus(false);
      setLoading(true);
      setReloadUsers(true);
    });
  };

  const classes = useStyles();
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 ">
          <h1>Usuarios</h1>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Id</TableCell>
                  <TableCell className={classes.tableCell}>Nombre</TableCell>
                  <TableCell className={classes.tableCell}>Apellidos</TableCell>
                  <TableCell className={classes.tableCell}>Email</TableCell>
                  <TableCell className={classes.tableCell}>
                    Documento de identidad
                  </TableCell>
                  <TableCell className={classes.tableCell}>Acciones</TableCell>
                </TableRow>
              </TableHead>
              {!loading && (
                <TableBody>
                  {users.map((row) => (
                    <TableRow key={row.usuarioId}>
                      <TableCell className={classes.tableCell}>
                        {row.usuarioId}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.nombre}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.apellidos}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.email}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.documentoDeIdentidad}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {savingNewStatus ? (
                          <CircularProgress />
                        ) : (
                          <ButtonGroup aria-label="Acciones">
                            {/* <Button
                              component="button"
                              color="primary"
                              variant="outlined"
                              startIcon={<Person />}
                              onClick={(e) => {
                                history.push(`/user/${row.usuarioId}`);
                              }}
                            >
                              Perfil
                            </Button> */}

                            {row.activo ? (
                              <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => {
                                  enableDisableUser(row.usuarioId, 0);
                                }}
                                startIcon={<Block />}
                              >
                                Desactivar
                              </Button>
                            ) : (
                              <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => {
                                  enableDisableUser(row.usuarioId, 1);
                                }}
                                startIcon={<VerifiedUser />}
                              >
                                Activar
                              </Button>
                            )}
                          </ButtonGroup>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {loading && (
            <div className="d-flex mt-2 justify-content-center">
              <CircularProgress />{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
