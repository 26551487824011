import authHeader from "./auth-header";
import axios from "axios";
import { API_URL, API } from "../AppConstants";
import { USER_ROLES } from "../AppConstants";

const getCategories = () => {
  let url = `${API_URL}${API.categoria.getAll}`;

  return axios.get(url, { headers: authHeader() }).then((response) => {
    return response.data;
  });
};

const exported = {
  getCategories,
};
export default exported;
