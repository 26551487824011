import React from "react";
import "./Home.scss";
import Logo from "../images/logo.png";
const Home = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-6 ">
          <div className="logo">
            <img src={Logo} alt="Logo - JPD- Judo Piramidal Dinámico" />
          </div>
          <p>
            Plataforma deportiva que permite realizar competencias individuales
            de judo
          </p>
          <br />
          <p>
            Si tiene problemas al utilizar nuestra plataforma puede escribirnos
            a <a href="mailto:soporte@judopd.com">soporte@judopd.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
