import axios from "axios";
import jwt_decode from "jwt-decode";
import { API_URL, API } from "../AppConstants.js";
import UserService from "./user.service";

const login = (username, password) => {
  const params = new URLSearchParams();
  params.append("_username", username);
  params.append("_password", password);
  let url = API_URL + API.autenticacion.getToken;
  return axios
    .post(url, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((response) => {
      if (response.data.token) {
        UserService.setToken(response.data.token);
        UserService.setUser(jwt_decode(response.data.token));
      }
      return response.data;
    });
};

const logout = () => {
  UserService.removeToken();
  UserService.removeUser();
  window.location = "/";
};

const exports = { login, logout };

export default exports;
