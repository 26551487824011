import "./App.scss";
import Home from "./Home/Home";
import Login from "./Login/Login";
import MainMenu from "./MainMenu/MainMenu";
import Users from "./Users/Users";
import Contact from "./Contact/Contact";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SportsClubs from "./SportsClubs/SportsClubs";
import SportsClubUsers from "./SportsClubUsers/SportsClubUsers";
import { SportsClubsPlayers } from "./SportsClubsPlayers/SportsClubsPlayers";
import Matches from "./matches/Matches";
import AccessDenied from "./AccessDenied/AccessDenied";
import UserProfile from "./UserProfile/UserProfile";
import Categories from "./Categories/Categories";
import Tournaments from "./Tournaments/Tournaments";
import ResetPassword from "./ResetPassword/ResetPassword";
import { QueryClient, QueryClientProvider } from "react-query";
import { EditMatch } from "./matches/EditMatch/EditMatch";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";

function App() {
  const queryClient = new QueryClient();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <QueryClientProvider client={queryClient}>
        <Router basename={"/web"}>
          <>
            <MainMenu></MainMenu>
            <div className="container-fluid">
              <Switch>
                <Route exact path="/">
                  <Home></Home>
                </Route>
                <Route path="/privacy-policy">
                  <PrivacyPolicy></PrivacyPolicy>
                </Route>
                <Route path="/user/login">
                  <Login></Login>
                </Route>
                <Route path="/contact">
                  <Contact></Contact>
                </Route>

                <Route exact path="/user/:id">
                  <UserProfile></UserProfile>
                </Route>
                <Route exact path="/user">
                  <Users></Users>
                </Route>
                <Route exact path="/reset-password/:key">
                  <ResetPassword></ResetPassword>
                </Route>
                <Route exact path="/sports-club">
                  <SportsClubs></SportsClubs>
                </Route>
                <Route exact path="/sports-club/:id/users">
                  <SportsClubUsers></SportsClubUsers>
                </Route>
                <Route exact path="/sports-club/:id/players">
                  <SportsClubsPlayers></SportsClubsPlayers>
                </Route>
                <Route exact path="/matches">
                  <Matches></Matches>
                </Route>
                <Route exact path="/matches/:id/edit">
                  <EditMatch></EditMatch>
                </Route>
                <Route exact path="/categories">
                  <Categories></Categories>
                </Route>
                <Route exact path="/tournaments">
                  <Tournaments></Tournaments>
                </Route>
                <Route exact path="/access-denied">
                  <AccessDenied></AccessDenied>
                </Route>
              </Switch>
            </div>
          </>
        </Router>
      </QueryClientProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
