import axios from "axios";
import { API_URL, API } from "../AppConstants";
import authHeader from "./auth-header";

const getTournaments = () => {
  let url = `${API_URL}${API.torneos.getAll}`;

  return axios.get(url, { headers: authHeader() }).then((response) => {
    return response.data;
  });
};
const exported = {
  getTournaments,
};
export default exported;
