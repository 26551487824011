import "./Matches.scss";
import {
  Breadcrumbs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import MatchService from "../services/match.service";
// import { Schedule } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const Matches = () => {
  const [matches, setMatches] = useState([]),
    [loading, setLoading] = useState(0),
    history = useHistory();

  useEffect(() => {
    setLoading(true);
    MatchService.getMatches().then((response) => {
      setMatches(response.combates);
      setLoading(false);
    });
  }, []);

  const useStyles = makeStyles({
    table: {},
    tableCell: {},
  });
  const classes = useStyles();
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <h1>Combates</h1>

          {loading === false && (
            <>
              <Breadcrumbs aria-label="breadcrumbs">
                <Link color="inherit" to="/">
                  Inicio
                </Link>
                <Typography color="textPrimary">Combates</Typography>
              </Breadcrumbs>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>Id</TableCell>
                      <TableCell className={classes.tableCell}>
                        Retador
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        Retado
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {matches.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.tableCell}>
                          {row.combateId}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.combatienteRetador.nombre +
                            " " +
                            row.combatienteRetador.apellidos}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.combatienteRetado.nombre +
                            " " +
                            row.combatienteRetado.apellidos}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {loading !== false && <CircularProgress />}
        </div>
      </div>
    </div>
  );
};

export default Matches;
