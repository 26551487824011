import "./MainMenu.scss";
import { Link } from "react-router-dom";
import logoHorizontal from "../images/logo-horizontal.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/auth";
import { useEffect } from "react";
import UserService from "../services/user.service";
import { API_URL } from "../AppConstants";
import { AccountBox } from "@material-ui/icons";

const MainMenu = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  console.log("user", user);
  useEffect(() => {
    // TODO implement functionality for roled base access
  }, [user]);

  const logOut = () => {
    dispatch(logout());
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <Link className="navbar-brand" to="/">
        <img className="logo" alt="Logo Judopd" src={logoHorizontal} />
      </Link>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto justify-content-between">
          <li className="nav-item active">
            <Link className="nav-link" to="/">
              Inicio <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to="/privacy-policy">
              Politica de privacidad
            </Link>
          </li>
          {UserService.isAdmin() && (
            <li className="nav-item">
              <Link className="nav-link" to="/user">
                Usuarios
              </Link>
            </li>
          )}
          {UserService.isAdmin() && (
            <li className="nav-item">
              <Link className="nav-link" to="/sports-club">
                Clubes deportivos
              </Link>
            </li>
          )}
          {UserService.isAdmin() && (
            <li className="nav-item">
              <Link className="nav-link" to="/matches">
                Combates
              </Link>
            </li>
          )}
          {UserService.isAdmin() && (
            <li className="nav-item">
              <Link className="nav-link" to="/categories">
                Categorias
              </Link>
            </li>
          )}
          {UserService.isAdmin() && (
            <li className="nav-item">
              <Link className="nav-link" to="/tournaments">
                Torneos
              </Link>
            </li>
          )}
          {UserService.isAdmin() && (
            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                Contacto
              </Link>
            </li>
          )}
        </ul>
        {isLoggedIn && (
          <>
            {
              // this was temporally disabled
            }
            {user.profileImageSm !== "" && false ? (
              <img
                src={API_URL + user.profileImageSm}
                className="profile-image"
                alt="Profile avatar"
              />
            ) : (
              <AccountBox className="profile-icon" />
            )}
            <span className="navbar-text user-name">{user.nombre}</span>
          </>
        )}

        <ul className="navbar-nav">
          {!isLoggedIn && (
            <li className="nav-item">
              <Link className="nav-link" to="/user/login">
                Autenticarse
              </Link>
            </li>
          )}

          {isLoggedIn && (
            <li className="nav-item">
              <Link className="nav-link" to="#" onClick={logOut}>
                Logout
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default MainMenu;
