import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import getSportClubs from "../services/sport-clubs.service";
import "./SportsClubs.scss";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import SportsKabaddi from "@material-ui/icons/SportsKabaddi";
import Block from "@material-ui/icons/Block";
import People from "@material-ui/icons/People";

const SportsClubs = () => {
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getSportClubs.getSportClubs().then((response) => {
      setClubs(response.clubs);
      setLoading(false);
    });
  }, []);
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <h1>Clubes deportivos</h1>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">Nombre</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              {!loading && (
                <TableBody>
                  {clubs.map((club, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{club.clubId}</TableCell>
                      <TableCell align="center">{club.nombre}</TableCell>
                      <TableCell align="center">
                        <ButtonGroup color="primary" aria-label="Acciones">
                          <Button
                            component={Link}
                            to={`/sports-club/${club.clubId}/users`}
                            startIcon={<People />}
                          >
                            Admins
                          </Button>
                          <Button
                            component={Link}
                            to={`/sports-club/${club.clubId}/players`}
                            startIcon={<SportsKabaddi />}
                          >
                            Deportistas
                          </Button>
                          {/* <Button color="secondary" startIcon={<Block />}>
                            Desactivar
                          </Button> */}
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {loading && (
            <div className="d-flex mt-2  justify-content-center">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SportsClubs;
